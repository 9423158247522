import(/* webpackMode: "eager", webpackExports: ["CookieProvider"] */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/[lng]/(Contexts)/CookieContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/[lng]/(Contexts)/RouteTrackerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/[lng]/components/CookieModal/CookieModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/[lng]/components/GTMTrackers/GTMProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/globalRedux/provider.jsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/mobile-responsive.css");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/app/styles/react-select-styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/fructidor/fructidor-ui/releases/20250402172754/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250402172754/node_modules/next/dist/client/script.js");
